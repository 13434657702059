import Footer from "../components/Footer";
import Header  from "../components/Header";


function NoPage(){
    return (
      <>
          <Header />
          <h2>Error 404 : Not found</h2>
          <Footer/>

      </>
   
    );
}

export default NoPage;